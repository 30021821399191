// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("/opt/build/repo/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-plans-js": () => import("/opt/build/repo/src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-plans-custom-reversity-plan-js": () => import("/opt/build/repo/src/pages/plans/custom-reversity-plan.js" /* webpackChunkName: "component---src-pages-plans-custom-reversity-plan-js" */),
  "component---src-pages-plans-one-time-deep-dive-js": () => import("/opt/build/repo/src/pages/plans/one-time-deep-dive.js" /* webpackChunkName: "component---src-pages-plans-one-time-deep-dive-js" */),
  "component---src-pages-plans-reversity-university-js": () => import("/opt/build/repo/src/pages/plans/reversity-university.js" /* webpackChunkName: "component---src-pages-plans-reversity-university-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

